.modal-90w {
  width: 90%;
  max-width: none !important;
}

.image-outer-wrapper {
  display: table;
}

.image-inner-wrapper {
  padding: 50% 0;
  height: 0;
}

.rotate {
  display: block;
  transform-origin: top left;
  margin-top: -50%;
  white-space: nowrap;
}

.rotate-270 {
  transform: rotate(-90deg) translate(-100%);
}
